// Disable prettier for this snippet
export default function Logo({ className }: { className?: string }) {
  return (
    // prettier-ignore
    <svg width="1000" height="217" viewBox="0 0 1000 217" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M155.58 160.9C158.518 160.9 160.9 158.518 160.9 155.58C160.9 152.642 158.518 150.26 155.58 150.26C152.642 150.26 150.26 152.642 150.26 155.58C150.26 158.518 152.642 160.9 155.58 160.9Z" fill="current"/>
      <path d="M108.26 160.9C111.198 160.9 113.58 158.518 113.58 155.58C113.58 152.642 111.198 150.26 108.26 150.26C105.322 150.26 102.94 152.642 102.94 155.58C102.94 158.518 105.322 160.9 108.26 160.9Z" fill="current"/>
      <path d="M60.9401 160.9C63.8783 160.9 66.2601 158.518 66.2601 155.58C66.2601 152.642 63.8783 150.26 60.9401 150.26C58.002 150.26 55.6201 152.642 55.6201 155.58C55.6201 158.518 58.002 160.9 60.9401 160.9Z" fill="current"/>
      <path d="M155.58 113.58C158.518 113.58 160.9 111.198 160.9 108.26C160.9 105.322 158.518 102.94 155.58 102.94C152.642 102.94 150.26 105.322 150.26 108.26C150.26 111.198 152.642 113.58 155.58 113.58Z" fill="current"/>
      <path d="M60.9401 113.58C63.8783 113.58 66.2601 111.198 66.2601 108.26C66.2601 105.322 63.8783 102.94 60.9401 102.94C58.002 102.94 55.6201 105.322 55.6201 108.26C55.6201 111.198 58.002 113.58 60.9401 113.58Z" fill="current"/>
      <path d="M155.58 66.2601C158.518 66.2601 160.9 63.8783 160.9 60.9401C160.9 58.002 158.518 55.6201 155.58 55.6201C152.642 55.6201 150.26 58.002 150.26 60.9401C150.26 63.8783 152.642 66.2601 155.58 66.2601Z" fill="current"/>
      <path d="M108.26 66.2601C111.198 66.2601 113.58 63.8783 113.58 60.9401C113.58 58.002 111.198 55.6201 108.26 55.6201C105.322 55.6201 102.94 58.002 102.94 60.9401C102.94 63.8783 105.322 66.2601 108.26 66.2601Z" fill="current"/>
      <path d="M60.9401 66.2601C63.8783 66.2601 66.2601 63.8783 66.2601 60.9401C66.2601 58.002 63.8783 55.6201 60.9401 55.6201C58.002 55.6201 55.6201 58.002 55.6201 60.9401C55.6201 63.8783 58.002 66.2601 60.9401 66.2601Z" fill="current"/>
      <path d="M133.88 100.29H111.45V81.4599C111.45 81.0057 111.309 80.5628 111.045 80.193C110.781 79.8233 110.408 79.5452 109.979 79.3976C109.549 79.25 109.084 79.2403 108.649 79.3698C108.213 79.4993 107.829 79.7615 107.55 80.1199L80.9102 112.71C80.6587 113.033 80.5031 113.42 80.4612 113.827C80.4193 114.234 80.4928 114.644 80.6732 115.011C80.8537 115.379 81.1339 115.688 81.4817 115.903C81.8296 116.119 82.231 116.232 82.6402 116.23H105.07V135.06C105.07 135.514 105.212 135.957 105.476 136.327C105.74 136.697 106.112 136.975 106.542 137.122C106.971 137.27 107.436 137.28 107.872 137.15C108.307 137.021 108.691 136.758 108.97 136.4L135.61 103.81C135.862 103.487 136.017 103.1 136.059 102.693C136.101 102.286 136.028 101.876 135.847 101.508C135.667 101.141 135.387 100.832 135.039 100.617C134.691 100.401 134.289 100.288 133.88 100.29V100.29Z" fill="current"/>
      <path d="M246.68 128.21V137.33H211.43V79.27H246.27V88.4H221V103.49H244.2V112.49H221V128.17L246.68 128.21Z" fill="current"/>
      <path d="M290.54 128.21V137.33H257.36V79.27H266.9V128.21H290.54Z" fill="current"/>
      <path d="M333.2 128.21V137.33H297.94V79.27H332.78V88.4H307.48V103.49H330.71V112.49H307.48V128.17L333.2 128.21Z" fill="current"/>
      <path d="M339.48 108.3C339.48 91.47 352.17 78.19 369.84 78.19C374.856 78.1064 379.806 79.3428 384.194 81.7754C388.582 84.208 392.253 87.7513 394.84 92.05L386.54 96.86C383.47 91.13 377.17 87.48 369.87 87.48C357.35 87.48 349.05 96.36 349.05 108.3C349.05 120.24 357.35 129.12 369.87 129.12C377.17 129.12 383.56 125.47 386.54 119.75L394.84 124.48C390.03 132.94 380.57 138.41 369.84 138.41C352.17 138.41 339.48 125.14 339.48 108.3Z" fill="current"/>
      <path d="M442.2 88.4H425.86V137.33H416.32V88.4H399.9V79.27H442.2V88.4Z" fill="current"/>
      <path d="M470.43 116.18H459.15V137.33H449.61V79.2699H472.83C475.284 79.2607 477.716 79.7373 479.986 80.6722C482.255 81.6072 484.317 82.9821 486.052 84.7176C487.788 86.4531 489.163 88.5149 490.098 90.7843C491.033 93.0536 491.509 95.4855 491.5 97.9399C491.468 101.569 490.358 105.107 488.31 108.104C486.262 111.101 483.37 113.421 480 114.77L493.18 137.33H482.7L470.43 116.18ZM459.15 107.64H472.83C474.067 107.605 475.285 107.326 476.414 106.819C477.543 106.312 478.561 105.588 479.41 104.686C480.258 103.785 480.92 102.725 481.358 101.567C481.795 100.41 482 99.1769 481.96 97.9399C481.96 92.4599 477.89 88.2299 472.83 88.2299H459.15V107.64Z" fill="current"/>
      <path d="M511.63 79.27V137.33H502.09V79.27H511.63Z" fill="current"/>
      <path d="M521.66 108.3C521.66 91.47 534.35 78.19 552.02 78.19C557.037 78.1064 561.987 79.3428 566.374 81.7754C570.762 84.208 574.434 87.7513 577.02 92.05L568.72 96.86C565.65 91.13 559.35 87.48 552.05 87.48C539.52 87.48 531.23 96.36 531.23 108.3C531.23 120.24 539.52 129.12 552.05 129.12C559.35 129.12 565.74 125.47 568.72 119.75L577.02 124.48C572.21 132.94 562.75 138.41 552.02 138.41C534.35 138.41 521.66 125.14 521.66 108.3Z" fill="current"/>
      <path d="M595.7 79.27V137.33H586.16V79.27H595.7Z" fill="current"/>
      <path d="M645.31 88.4H629V137.33H619.47V88.4H603V79.27H645.31V88.4Z" fill="current"/>
      <path d="M680.06 114.28V137.33H670.52V114.28L650.28 79.28H661.15L675.33 105L689.43 79.28H700.3L680.06 114.28Z" fill="current"/>
      <path d="M787.8 137.33H778.26V95.94L760.6 125.14H759.44L741.77 95.86V137.33H732.23V79.27H742.51L760.02 108.27L777.6 79.27H787.8V137.33Z" fill="current"/>
      <path d="M834.08 125.64H809.36L805.3 137.33H794.93L816 79.27H827.44L848.44 137.33H838.14L834.08 125.64ZM830.93 116.64L821.72 90.43L812.51 116.64H830.93Z" fill="current"/>
      <path d="M896.15 98.3501C896.179 100.878 895.699 103.386 894.74 105.725C893.78 108.064 892.361 110.186 890.565 111.966C888.769 113.746 886.635 115.147 884.287 116.085C881.94 117.024 879.428 117.481 876.9 117.43H865V137.33H855.5V79.2701H876.9C879.429 79.212 881.945 79.6646 884.295 80.6008C886.646 81.5369 888.783 82.9374 890.58 84.7185C892.377 86.4996 893.797 88.6248 894.754 90.967C895.711 93.3091 896.186 95.8202 896.15 98.3501V98.3501ZM886.61 98.3501C886.61 92.4601 882.54 88.2301 876.9 88.2301H865V108.47H876.9C882.54 108.47 886.61 104.16 886.61 98.3501Z" fill="current"/>
      <path d="M901.41 124.15L909.62 119.34C911.7 125.22 916.18 129.12 924.06 129.12C931.61 129.12 934.84 125.72 934.84 121.49C934.84 116.35 930.28 114.61 921.65 111.95C912.65 109.22 903.82 105.95 903.82 94.9501C903.82 84.2501 912.69 78.1901 922.48 78.1901C926.743 78.0835 930.941 79.2437 934.544 81.524C938.147 83.8043 940.992 87.1021 942.72 91.0001L934.67 95.6401C932.6 91.0001 929 87.4801 922.48 87.4801C916.92 87.4801 913.36 90.3001 913.36 94.7001C913.36 99.1001 916.59 101.09 925.05 103.7C934.67 106.77 944.38 109.76 944.38 121.29C944.38 131.9 935.92 138.37 923.81 138.37C912.36 138.41 904.48 132.77 901.41 124.15Z" fill="current"/>
    </svg>
  );
}

export function LogoIcon({ className }: { className?: string }) {
  // prettier-ignore
  return (
  <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M1.33861 2.66667C2.0779 2.66667 2.67722 2.06971 2.67722 1.33333C2.67722 0.596953 2.0779 0 1.33861 0C0.599315 0 0 0.596953 0 1.33333C0 2.06971 0.599315 2.66667 1.33861 2.66667Z" fill="currentColor" />
    <path d="M12.0475 2.66667C12.7868 2.66667 13.3861 2.06971 13.3861 1.33333C13.3861 0.596953 12.7868 0 12.0475 0C11.3082 0 10.7089 0.596953 10.7089 1.33333C10.7089 2.06971 11.3082 2.66667 12.0475 2.66667Z" fill="currentColor" />
    <path d="M22.7563 2.66667C23.4956 2.66667 24.0949 2.06971 24.0949 1.33333C24.0949 0.596953 23.4956 0 22.7563 0C22.017 0 21.4177 0.596953 21.4177 1.33333C21.4177 2.06971 22.017 2.66667 22.7563 2.66667Z" fill="currentColor" />
    <path d="M1.33861 23.9999C2.0779 23.9999 2.67722 23.403 2.67722 22.6666C2.67722 21.9302 2.0779 21.3333 1.33861 21.3333C0.599315 21.3333 0 21.9302 0 22.6666C0 23.403 0.599315 23.9999 1.33861 23.9999Z" fill="currentColor" />
    <path d="M12.0475 23.9999C12.7868 23.9999 13.3861 23.403 13.3861 22.6666C13.3861 21.9302 12.7868 21.3333 12.0475 21.3333C11.3082 21.3333 10.7089 21.9302 10.7089 22.6666C10.7089 23.403 11.3082 23.9999 12.0475 23.9999Z" fill="currentColor" />
    <path d="M22.7563 13.3334C23.4956 13.3334 24.0949 12.7364 24.0949 12C24.0949 11.2637 23.4956 10.6667 22.7563 10.6667C22.017 10.6667 21.4177 11.2637 21.4177 12C21.4177 12.7364 22.017 13.3334 22.7563 13.3334Z" fill="currentColor" />
    <path d="M1.33861 13.3334C2.0779 13.3334 2.67722 12.7364 2.67722 12C2.67722 11.2637 2.0779 10.6667 1.33861 10.6667C0.599315 10.6667 0 11.2637 0 12C0 12.7364 0.599315 13.3334 1.33861 13.3334Z" fill="currentColor" />
    <path d="M22.7563 23.9999C23.4956 23.9999 24.0949 23.403 24.0949 22.6666C24.0949 21.9302 23.4956 21.3333 22.7563 21.3333C22.017 21.3333 21.4177 21.9302 21.4177 22.6666C21.4177 23.403 22.017 23.9999 22.7563 23.9999Z" fill="currentColor" />
    <path d="M17.6773 10.1666H12.7485V5.83497C12.7486 5.73049 12.7174 5.6286 12.6594 5.54354C12.6015 5.45849 12.5196 5.39452 12.4252 5.36057C12.3308 5.32661 12.2286 5.32437 12.1329 5.35416C12.0373 5.38395 11.9529 5.44428 11.8915 5.52672L6.03771 13.0237C5.98244 13.0979 5.94825 13.187 5.93904 13.2806C5.92983 13.3742 5.94598 13.4687 5.98564 13.5531C6.0253 13.6376 6.08687 13.7087 6.1633 13.7583C6.23973 13.8078 6.32795 13.8339 6.41786 13.8334H11.3466V18.1651C11.3466 18.2695 11.3777 18.3714 11.4357 18.4565C11.4937 18.5415 11.5756 18.6055 11.67 18.6395C11.7644 18.6734 11.8665 18.6757 11.9622 18.6459C12.0579 18.6161 12.1423 18.5558 12.2036 18.4733L18.0574 10.9763C18.1127 10.9021 18.1469 10.8131 18.1561 10.7194C18.1653 10.6258 18.1491 10.5314 18.1095 10.4469C18.0698 10.3624 18.0083 10.2913 17.9318 10.2418C17.8554 10.1922 17.7672 10.1662 17.6773 10.1666V10.1666Z" fill="currentColor" />
  </svg>
  );
}

export function LogoText({ className }: { className?: string }) {
  return (
    <svg
      width="179"
      height="24"
      viewBox="0 0 179 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.8309 16.1266V18.2H12V5H19.7398V7.07572H14.126V10.5064H19.2799V12.5526H14.126V16.1175L19.8309 16.1266Z"
        fill="currentColor"
      />
      <path
        d="M30.0712 16.1266V18.2H22.2404V5H24.4919V16.1266H30.0712Z"
        fill="currentColor"
      />
      <path
        d="M39.7092 16.1266V18.2H31.8783V5H39.6159V7.07572H33.9971V10.5064H39.1562V12.5526H33.9971V16.1175L39.7092 16.1266Z"
        fill="currentColor"
      />
      <path
        d="M40.9139 11.6004C40.9139 7.91161 43.6755 5.00087 47.5209 5.00087C48.6126 4.98253 49.6898 5.25354 50.6447 5.78672C51.5996 6.3199 52.3985 7.09652 52.9614 8.03873L51.1552 9.093C50.4871 7.83708 49.1161 7.03707 47.5274 7.03707C44.8028 7.03707 42.9966 8.98341 42.9966 11.6004C42.9966 14.2175 44.8028 16.1638 47.5274 16.1638C49.1161 16.1638 50.5067 15.3638 51.1552 14.1101L52.9614 15.1468C51.9147 17.0011 49.856 18.2 47.5209 18.2C43.6755 18.2 40.9139 15.2915 40.9139 11.6004Z"
        fill="currentColor"
      />
      <path
        d="M64.4065 7.07572H60.4508V18.2H58.1413V7.07572H54.1662V5H64.4065V7.07572Z"
        fill="currentColor"
      />
      <path
        d="M70.2168 13.3915H67.7216V18.2H65.6113V5.00003H70.7477C71.2906 4.99793 71.8286 5.10628 72.3306 5.31885C72.8326 5.53142 73.2887 5.84399 73.6726 6.23856C74.0565 6.63313 74.3606 7.10189 74.5674 7.61783C74.7742 8.13376 74.8797 8.68666 74.8776 9.24466C74.8706 10.0698 74.625 10.8742 74.172 11.5555C73.719 12.2369 73.0792 12.7644 72.3337 13.071L75.2493 18.2H72.931L70.2168 13.3915ZM67.7216 11.45H70.7477C71.0214 11.442 71.2908 11.3786 71.5406 11.2634C71.7904 11.1482 72.0155 10.9834 72.2032 10.7785C72.3908 10.5736 72.5373 10.3326 72.6341 10.0694C72.7309 9.80617 72.7762 9.5259 72.7673 9.24466C72.7673 7.99878 71.867 7.03709 70.7477 7.03709H67.7216V11.45Z"
        fill="currentColor"
      />
      <path d="M79.4659 5V18.2H77.0564V5H79.4659Z" fill="currentColor" />
      <path
        d="M81.8754 11.6004C81.8754 7.91161 84.637 5.00087 88.4823 5.00087C89.574 4.98253 90.6512 5.25354 91.6061 5.78672C92.561 6.3199 93.3599 7.09652 93.9228 8.03873L92.1166 9.093C91.4485 7.83708 90.0775 7.03707 88.4889 7.03707C85.7621 7.03707 83.958 8.98341 83.958 11.6004C83.958 14.2175 85.7621 16.1638 88.4889 16.1638C90.0775 16.1638 91.4681 15.3638 92.1166 14.1101L93.9228 15.1468C92.8761 17.0011 90.8174 18.2 88.4823 18.2C84.637 18.2 81.8754 15.2915 81.8754 11.6004Z"
        fill="currentColor"
      />
      <path d="M98.1395 5V18.2H95.73V5H98.1395Z" fill="currentColor" />
      <path
        d="M109.585 7.07572H105.637V18.2H103.33V7.07572H99.3442V5H109.585V7.07572Z"
        fill="currentColor"
      />
      <path
        d="M117.245 12.9587V18.2H115.177V12.9587L110.789 5H113.146L116.219 10.8485L119.276 5H121.632L117.245 12.9587Z"
        fill="currentColor"
      />
      <path
        d="M142.715 18.2H140.543V8.78994L136.523 15.4286H136.259L132.237 8.77175V18.2H130.065V5H132.405L136.391 11.5932L140.393 5H142.715V18.2Z"
        fill="currentColor"
      />
      <path
        d="M153.337 15.5423H147.771L146.857 18.2H144.522L149.266 5H151.842L156.57 18.2H154.251L153.337 15.5423ZM152.627 13.4961L150.554 7.53724L148.48 13.4961H152.627Z"
        fill="currentColor"
      />
      <path
        d="M168.014 9.33861C168.021 9.9133 167.907 10.4834 167.68 11.0152C167.453 11.5469 167.116 12.0294 166.69 12.434C166.265 12.8385 165.758 13.157 165.202 13.3704C164.645 13.5837 164.05 13.6877 163.451 13.6761H160.629V18.2H158.377V5.00113H163.451C164.05 4.98793 164.647 5.09081 165.204 5.30363C165.761 5.51644 166.268 5.83482 166.694 6.23972C167.12 6.64463 167.456 7.12774 167.683 7.66019C167.91 8.19264 168.023 8.76349 168.014 9.33861V9.33861ZM165.753 9.33861C165.753 7.99963 164.788 7.03802 163.451 7.03802H160.629V11.6392H163.451C164.788 11.6392 165.753 10.6594 165.753 9.33861Z"
        fill="currentColor"
      />
      <path
        d="M169.22 15.0813L171.061 14.0264C171.528 15.3159 172.532 16.1713 174.3 16.1713C175.993 16.1713 176.718 15.4256 176.718 14.4979C176.718 13.3706 175.695 12.989 173.759 12.4056C171.741 11.8069 169.76 11.0897 169.76 8.67723C169.76 6.33054 171.75 5.00148 173.945 5.00148C174.902 4.97809 175.843 5.23255 176.651 5.73266C177.459 6.23276 178.098 6.95602 178.485 7.81092L176.68 8.82855C176.215 7.81092 175.408 7.03893 173.945 7.03893C172.698 7.03893 171.9 7.6574 171.9 8.6224C171.9 9.58739 172.624 10.0238 174.522 10.5962C176.68 11.2695 178.858 11.9253 178.858 14.454C178.858 16.781 176.96 18.2 174.244 18.2C171.676 18.2087 169.908 16.9718 169.22 15.0813Z"
        fill="currentColor"
      />
    </svg>
  );
}
